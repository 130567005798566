import React from "react";
import styled from "@emotion/styled";
// components
import { FormLayout } from "../components/layout/formLayout";
// utils
import { Colors } from "../components/utils/colors";

const ConfirmPage = () => {
  return (
    <FormLayout
      flow01="false"
      flow02="true"
      flow03="false"
      progress01="true"
      progress02="true"
      progress03="true"
      progress04="true"
      visibleBack="true"
      visibleNext="true"
      hrefBack="/form05"
      hrefNext="/thanks/"
      textBack="前の質問に戻る"
      textNext="この内容で送信する"
      progress05="true"
    >
      <SForm>
        <div>
          <span>
            ■会社名
            <p>デマンドリンク株式会社</p>
          </span>
        </div>
        <div>
          <span>
            ■お名前
            <p>デマンド太郎</p>
          </span>
        </div>
        <div>
          <span>
            ■メールアドレス
            <p>sample@xxxx.com</p>
          </span>
        </div>
        <div>
          <span>
            ■関心のある商品
            <p>ゴルフ関連製品</p>
            <p>付属ソフト関連製品</p>
          </span>
        </div>
        <div>
          <span>
            ■お問い合わせ内容
            <p>
              XXX製品の無料デモのご相談です。
              <br />
              サンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキスト
            </p>
          </span>
        </div>
      </SForm>
    </FormLayout>
  );
};

export default ConfirmPage;

const SForm = styled.div`
  min-height: calc(100% - 64px - 64px - 3rem);
  width: 800px;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 3rem;
  div {
    margin-top: 3rem;
    span {
      display: flex;
      flex-direction: column;
      font-size: 0.9rem;
      font-weight: 500;
      color: ${Colors.dark};
      line-height: 1;
      p {
        opacity: 0.8;
        margin-top: 1.5rem;
        width: 100%;
        border: none;
        font-size: 0.9rem;
        line-height: 1;
        transition: border 0.2s;
      }
    }
  }
  /* TB */
  @media screen and (max-width: 1023px) {
    width: 100%;
    height: auto;
    margin-bottom: 7rem;
  }
`;
